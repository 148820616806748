import React, { useState, useEffect } from 'react';
import Top from './Top';
import axios from 'axios';

function Statistics() {
    const [stats, setStats] = useState({
        fibreSearches: 0,
        wirelessSearches: 0,
        totalSearchesThisMonth: 0,
        totalSearchesToDate: 0,
    });

    const [license, setLicense] = useState('Standard');

    useEffect(() => {
        fetchStatistics();
    }, []);

    const fetchStatistics = async () => {
        try {
            const response = await axios.get('/api/search-statistics');
            console.log('Fetched Stats:', response.data);
            setStats(response.data);
        } catch (error) {
            console.error('Error fetching statistics:', error);
        }
    };

    return (
        <div style={styles.pageContainer}>
            <Top />
            <div style={styles.container}>
                <h2 style={styles.heading}>Search Statistics</h2>

                <div style={styles.scrollableContent}>
                    <div style={styles.statItem}>
                        <p><strong>Fibre Searches (This Month):</strong> {stats.fibreSearches}</p>
                    </div>

                    <div style={styles.statItem}>
                        <p><strong>Wireless Searches Excluding Co-ordinate Searches(This Month):</strong> {stats.wirelessSearches}</p>
                    </div>

                    <div style={styles.statItem}>
                        <p><strong>Total Searches Including Co-ordinate Searches(This Month):</strong> {stats.totalSearchesThisMonth}</p>
                    </div>

                    <div style={styles.statItem}>
                        <p><strong>Total Searches Including Co-ordinate Searches(To Date):</strong> {stats.totalSearchesToDate}</p>
                    </div>

                    <h2 style={styles.heading}>Current License</h2>
                    <div style={styles.licenseItem}>
                        <p><strong>License Type:</strong> {license}</p>
                        <a href="mailto:support@smart-serve.net" style={styles.mailLink}>
                            <button style={styles.printButton}>Upgrade - Contact Us</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    pageContainer: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    container: {
        flexGrow: 1,
        margin: '2% auto',
        border: '1px solid slategray',
        borderRadius: '12px',
        padding: '20px',
        width: '80%',
        maxWidth: '1000px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    heading: {
        fontSize: '22px',
        marginBottom: '15px',
        textAlign: 'center',
    },
    scrollableContent: {
        flexGrow: 1,
        overflowY: 'auto',  // Enables vertical scrolling when content overflows
        maxHeight: '80vh',  // Adjust the maximum height so content scrolls when needed
        paddingRight: '10px',
    },
    statItem: {
        marginBottom: '10px',
        backgroundColor: '#f2f2f2',
        padding: '8px',
        borderRadius: '8px',
        border: '1px solid slategray',
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    licenseItem: {
        marginTop: '30px',
        backgroundColor: '#f2f2f2',
        padding: '10px',
        borderRadius: '8px',
        border: '1px solid slategray',
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    printButton: {
        padding: '6px 10px',
        borderRadius: '5px',
        backgroundColor: '#61acb4',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        marginLeft: '10px',
        fontSize: '14px',
    },
    mailLink: {
        textDecoration: 'none',  // Ensures no underline for the link
    },
};

export default Statistics;
